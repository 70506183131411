import React from "react";
import PropTypes from "prop-types";

import APIError from "@/utils/apiError";
import useTranslations from "@/utils/useTranslations";

function ErrorFallback({ error, resetErrorBoundary }) {
  const t = useTranslations("common");
  return (
    <>
      <h1>{t("generalError.title")}</h1>
      <div className="errorBox">
        <p>
          {error instanceof APIError
            ? t(`generalError.${error.message}`)
            : error.message}
        </p>
        <button type="button" onClick={resetErrorBoundary}>
          {t("backToHome")}
        </button>
      </div>
    </>
  );
}
ErrorFallback.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default ErrorFallback;
