import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { NextIntlProvider } from "next-intl";
import { Toaster } from "react-hot-toast";
import { ErrorBoundary } from "react-error-boundary";

import "./global.scss";
import "normalize.css";

import fixTimeoutTransition from "@/utils/fixTimeoutTransition";

import Navigation from "@/components/Navigation/Navigation";
import Footer from "@/components/Footer/Footer";
import LoadingDots from "@/components/LoadingDots/LoadingDots";
import ErrorFallback from "@/components/ErrorFallback/ErrorFallback";
import usePrevious from "@/utils/usePrevious";

if (process.env.NODE_ENV === "production") fixTimeoutTransition(300);

function App({ Component, pageProps }) {
  return (
    <NextIntlProvider messages={pageProps.messages}>
      <TransitionLayout>
        <Component {...pageProps} />
      </TransitionLayout>
    </NextIntlProvider>
  );
}
App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape({ messages: PropTypes.shape({}) }).isRequired,
};

function TransitionLayout({ children }) {
  const [displayChildren, setDisplayChildren] = useState(children);
  const [transitionStage, setTransitionStage] = useState("fadeIn");
  const router = useRouter();
  const prevRouter = usePrevious(router);

  useEffect(() => {
    if (router.pathname !== prevRouter.pathname) setTransitionStage("fadeOut");
  }, [router.pathname]);

  useEffect(() => {
    if (router.locale === prevRouter.locale) return;
    const regex = /^(\/news|\/commands|\/privacy|\/terms)/;
    const shouldRefreshContent =
      regex.test(router.pathname) && regex.test(prevRouter.pathname);
    if (shouldRefreshContent) setDisplayChildren(children);
  }, [router.locale]);

  const onReset = () => {
    router.replace("/");
  };

  return (
    <>
      <Navigation />
      {transitionStage === "fadeOut" && (
        <div className="loadingBox">
          <LoadingDots />
        </div>
      )}
      <main
        className={`contents ${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === "fadeOut") {
            setTransitionStage("fadeIn");
            setDisplayChildren(children);
          }
        }}
      >
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={onReset}
          resetKeys={[router.pathname]}
        >
          {displayChildren}
        </ErrorBoundary>
      </main>
      <Footer />
      <Toaster />
    </>
  );
}
TransitionLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
