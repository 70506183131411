import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import Link from "next/link";
import { ChevronDown } from "lucide-react";

import useTranslations from "@/utils/useTranslations";
import useUserData from "@/utils/useUserData";

import OAuthPopup from "../OAuthPopup/OAuthPopup";
import LoadingDots from "../LoadingDots/LoadingDots";

import css from "./Navigation.module.scss";

function LoginOrUserInfo() {
  const { userData, isLoading, onLogin, onLogout } = useUserData();

  if (isLoading) {
    return <LoadingDots />;
  }
  if (userData && userData.logged_in) {
    return <UserInfo userData={userData} onLogout={onLogout} />;
  }
  return <LoginButton onLogin={onLogin} />;
}

function LoginButton({ onLogin }) {
  const t = useTranslations("common");
  return (
    <OAuthPopup
      url="/api/login"
      succeededUrl="/api/authorize"
      onSuccess={() => onLogin(`/dashboard`)}
      className={css.loginButton}
    >
      {t("login")}
    </OAuthPopup>
  );
}
LoginButton.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

function UserInfo({ userData, onLogout }) {
  const t = useTranslations("common");
  const router = useRouter();

  const languages = router.locales.map((code) => {
    const name = new Intl.DisplayNames(code, {
      type: "language",
    }).of(code.split("-")[0]);

    return (
      <li key={code}>
        <Link href={router.asPath} locale={code}>
          {name}
        </Link>
      </li>
    );
  });

  return (
    <details className={`${css.userInfoContainer} ${css.dMenu}`}>
      <summary className={css.userInfo}>
        <picture>
          {userData.avatar && (
            <source
              srcSet={`https://cdn.discordapp.com/avatars/${userData.id}/${userData.avatar}.webp?size=64`}
              type="image/webp"
            />
          )}
          {userData.avatar && (
            <source
              srcSet={`https://cdn.discordapp.com/avatars/${userData.id}/${userData.avatar}.jpg?size=64`}
              type="image/jpeg"
            />
          )}
          <source srcSet="/asset/default_icon.webp" type="image/webp" />
          <img src="/asset/default_icon.jpg" alt={userData.username} />
        </picture>
        <span>{userData.username}</span>
        <ChevronDown size={16} />
      </summary>
      <ul className={css.userInfoMenu}>
        <li>
          <button type="button">{t("languages")}</button>
          <ul className={css.userInfoMenu}>{languages}</ul>
        </li>
        <li>
          <button type="button" onClick={onLogout}>
            {t("logout")}
          </button>
        </li>
      </ul>
    </details>
  );
}
UserInfo.propTypes = {
  userData: PropTypes.shape({
    username: PropTypes.string,
    discriminator: PropTypes.string,
    id: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default LoginOrUserInfo;
