import { mutate } from "swr";
import useSWRImmutable from "swr/immutable";
import { useRouter } from "next/router";
import toast from "react-hot-toast";

import apiHelper from "./apiHelper";
import useTranslations from "./useTranslations";

const fetcher = (...args) =>
  apiHelper()
    .get(...args)
    .then((results) => {
      const { data } = results;
      console.info("UserData:", data);
      return data;
    });

function useUserData(options) {
  const isServer = typeof window === "undefined";
  const { data: userData, error } = useSWRImmutable("user", fetcher, {
    ...options,
  });
  const router = useRouter();
  const isLoading = isServer || (!error && !userData);
  const t = useTranslations("common");

  const onLogin = (navigateLocation) => {
    router.push(navigateLocation);
    mutate("user");
    toast.success(t("loggedIn"));
  };

  const onLogout = () => {
    apiHelper()
      .get("logout")
      .then(({ data: newData }) => {
        console.info("UserData:", newData);

        router.replace("/");
        mutate("user", newData);
        toast.success(t("loggedOut"));
      })
      .catch((e) => {
        console.error("Error while logging out:", e.response.data);
      });
  };

  return { userData, isLoading, onLogin, onLogout };
}

export default useUserData;
