import axios from "axios";
import { API_BASE } from "./constants";

const createAxiosInstance = (minimumDelay) => {
  const isServer = typeof window === "undefined";
  const axiosInstance = axios.create({
    baseURL: isServer ? API_BASE : "/api",
    withCredentials: true,
  });

  if (!minimumDelay) return axiosInstance;

  axiosInstance.interceptors.request.use(
    (config) => ({
      ...config,
      p0: performance.now(),
    }),
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    async (response) => {
      const latency = performance.now() - response.config.p0;
      const shouldNotDelay = minimumDelay < latency;

      if (shouldNotDelay) {
        return response;
      }

      const remainder = minimumDelay - latency;
      const [responseWithDelay] = await Promise.all([
        response,
        // eslint-disable-next-line no-promise-executor-return
        new Promise((resolve) => setTimeout(resolve, remainder)),
      ]);

      return responseWithDelay;
    },
    (error) => Promise.reject(error),
  );

  return axiosInstance;
};

export default createAxiosInstance;
