import React, { useState, useEffect } from "react";
import Link from "next/link";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { CSSTransition } from "react-transition-group";
import { Menu, X } from "lucide-react";

import useTranslations from "@/utils/useTranslations";
import usePrevious from "@/utils/usePrevious";
import {
  DISCORD_SERVER_INVITE,
  CROWDIN_PAGE,
  DONATE_PAGE,
} from "@/utils/constants";

import LoginOrUserInfo from "./UserInfo";

import css from "./Navigation.module.scss";

const MOBILE_THRESHOLD = 675;

function Navigation() {
  const [isCompact, setCompact] = useState(false);
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const isCompactPrev = usePrevious(isCompact);

  const handleResize = (entries) => {
    const { width } = entries[0].contentRect;
    if (width <= MOBILE_THRESHOLD) {
      setCompact(true);
    } else if (width > MOBILE_THRESHOLD) {
      setCompact(false);
    }
  };

  useEffect(() => {
    setCompact(document.documentElement.clientWidth <= MOBILE_THRESHOLD);

    const resizeObserver = new ResizeObserver((entries) => {
      handleResize(entries);
    });

    resizeObserver.observe(document.documentElement);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (isCompact !== isCompactPrev) setSideMenuOpen(false);
  }, [isCompact, isCompactPrev]);

  const onSideMenuToggle = () => {
    setSideMenuOpen((prev) => !prev);
  };

  const onNavClick = () => {
    setSideMenuOpen(false);
  };

  return (
    <section className={css.container}>
      <div className={css.navigation}>
        <Link href="/" className={css.logo}>
          <img src="/asset/logo.svg" alt="logo" />
          <span>SwarmBot</span>
        </Link>
        <MenuContainer
          isCompact={isCompact}
          sideMenuOpen={isSideMenuOpen}
          onSideMenuToggle={onSideMenuToggle}
          onNavClick={onNavClick}
        />
        <LoginOrUserInfo />
      </div>
    </section>
  );
}

function MenuContainer({
  isCompact,
  sideMenuOpen,
  onSideMenuToggle,
  onNavClick,
}) {
  const menuContent = (
    <MenuContent isCompact={isCompact} onNavClick={onNavClick} />
  );
  return (
    <nav className={css.menuContainer}>
      {!isCompact && menuContent}
      {isCompact && (
        <SideMenu
          sideMenuOpen={sideMenuOpen}
          onSideMenuToggle={onSideMenuToggle}
        >
          {menuContent}
        </SideMenu>
      )}
    </nav>
  );
}
MenuContainer.propTypes = {
  isCompact: PropTypes.bool.isRequired,
  sideMenuOpen: PropTypes.bool.isRequired,
  onSideMenuToggle: PropTypes.func.isRequired,
  onNavClick: PropTypes.func.isRequired,
};

function SideMenu({ sideMenuOpen, onSideMenuToggle, children }) {
  const nodeRef = React.useRef();
  return (
    <>
      <button
        type="button"
        className={css.sideMenuButton}
        onClick={onSideMenuToggle}
        aria-label="Toggle menu"
      >
        {sideMenuOpen ? <X size={32} /> : <Menu size={32} />}
      </button>
      <CSSTransition
        in={sideMenuOpen}
        timeout={500}
        classNames={{
          enter: css.sideMenuEnter,
          enterDone: css.sideMenuEnterDone,
          exit: css.sideMenuExit,
          exitDone: css.sideMenuExitDone,
        }}
        nodeRef={nodeRef}
      >
        <div className={css.sideMenu} ref={nodeRef}>
          <button
            className={css.sideMenuCloser}
            onClick={onSideMenuToggle}
            type="button"
            aria-label="Close menu"
          />
          {children}
        </div>
      </CSSTransition>
    </>
  );
}
SideMenu.propTypes = {
  sideMenuOpen: PropTypes.bool.isRequired,
  onSideMenuToggle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

function MenuContent({ isCompact, onNavClick }) {
  const t = useTranslations("common");
  const router = useRouter();

  return (
    <ul className={`${css.menuContent} ${isCompact ? css.compact : ""}`}>
      <li>
        <Link
          href="/"
          className={router.asPath === "/" ? css.active : ""}
          onClick={onNavClick}
        >
          {t("home")}
        </Link>
      </li>
      <li>
        <Link
          href="/news"
          className={router.asPath.startsWith("/news") ? css.active : ""}
          onClick={onNavClick}
        >
          {t("news")}
        </Link>
      </li>
      <li>
        <Link
          href="/commands"
          className={router.asPath === "/commands" ? css.active : ""}
          onClick={onNavClick}
        >
          {t("commands")}
        </Link>
      </li>
      <li>
        <Link
          href="/dashboard"
          className={router.asPath.startsWith("/dashboard") ? css.active : ""}
          onClick={onNavClick}
        >
          {t("dashboard")}
        </Link>
      </li>
      <li>
        <a
          href={DISCORD_SERVER_INVITE}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("supportServer")}
        </a>
      </li>
      <li>
        <a href={CROWDIN_PAGE} target="_blank" rel="noopener noreferrer">
          {t("translate")}
        </a>
      </li>
      <li>
        <a href={DONATE_PAGE} target="_blank" rel="noopener noreferrer">
          {t("donate")}
        </a>
      </li>
    </ul>
  );
}
MenuContent.propTypes = {
  isCompact: PropTypes.bool.isRequired,
  onNavClick: PropTypes.func.isRequired,
};

export default Navigation;
