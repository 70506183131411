import React from "react";
import css from "./LoadingDots.module.scss";

function LoadingDots() {
  return (
    <div className={css.dots}>
      <div />
      <div />
      <div />
    </div>
  );
}

export default LoadingDots;
