import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

const createPopup = ({ url }) => {
  const [width, height] = [450, 500];
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const externalPopup = window.open(
    url,
    "Login with Discord",
    `width=${width},height=${height},left=${left},top=${top}`,
  );
  return externalPopup;
};

function OAuthPopup({ url, succeededUrl, onSuccess, className, children }) {
  const [externalWindow, setExternalWindow] = useState();
  const intervalRef = useRef();

  const clearTimer = () => {
    window.clearInterval(intervalRef.current);
  };

  const onContainerClick = () => {
    setExternalWindow(
      createPopup({
        url,
      }),
    );
  };

  useEffect(() => {
    if (externalWindow) {
      intervalRef.current = window.setInterval(() => {
        try {
          const currentUrl = externalWindow.location.href;
          const { protocol, host } = window.location;
          if (!currentUrl.startsWith(`${protocol}//${host}${succeededUrl}`)) {
            return;
          }
          const params = new URLSearchParams(externalWindow.location.search);
          onSuccess(params);
          clearTimer();
          externalWindow.close();
        } catch (error) {
          // eslint-ignore-line
        } finally {
          if (!externalWindow || externalWindow.closed) {
            clearTimer();
          }
        }
      }, 700);
    }
    return () => {
      if (externalWindow) externalWindow.close();
    };
  }, [externalWindow]);

  return (
    <button
      type="button"
      onClick={() => {
        onContainerClick();
      }}
      className={className}
    >
      {children}
    </button>
  );
}
OAuthPopup.propTypes = {
  url: PropTypes.string.isRequired,
  succeededUrl: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
OAuthPopup.defaultProps = {
  className: "",
};

export default OAuthPopup;
