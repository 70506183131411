import { useMemo } from "react";
import { useTranslations as useNextTranslation, useLocale } from "next-intl";

function useTranslations(namespace) {
  const locale = useLocale();
  const t = useNextTranslation();
  const T = useMemo(() => t, [locale]);
  return (key, options) => T(namespace ? `${namespace}.${key}` : key, options);
}

export default useTranslations;
