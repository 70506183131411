import React from "react";
import { Languages } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";

import useTranslations from "@/utils/useTranslations";

import css from "./Footer.module.scss";

function Footer() {
  const t = useTranslations("common");
  const router = useRouter();

  return (
    <footer className={css.container}>
      <small>© 2022 soya_daizu All rights reserved.</small>
      <label htmlFor="pageLang" className={css.row} aria-label={t("languages")}>
        <Languages />
        <select
          id="pageLang"
          className={css.pageLang}
          value={router.locale}
          onChange={(e) => {
            router.push(router.asPath, undefined, {
              locale: e.target.value,
              scroll: false,
            });
          }}
        >
          {router.locales.map((code) => {
            const name = new Intl.DisplayNames(code, {
              type: "language",
            }).of(code.split("-")[0]);

            return (
              <option key={code} value={code}>
                {name}
              </option>
            );
          })}
        </select>
      </label>
      <div className={css.row}>
        <Link href="/privacy">{t("privacy")}</Link>
        <Link href="/terms">{t("terms")}</Link>
      </div>
    </footer>
  );
}

export default Footer;
